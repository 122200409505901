<template>
  <input
    class="search text-sm text-white bg-[#202225] p-4"
    placeholder="Add song from Youtube..."
    required
    v-model="searchQuery"
    @keyup.enter="onInput"
  />
</template>

<script>
export default {
  name: "SearchBar",
  data: () => {
    return {
      searchQuery: "",
    };
  },
  methods: {
    onInput: function () {
      this.$emit("termChange", this.searchQuery);
    },
  },
};
</script>

<style scoped>
input {
  width: 75%;
}
div {
  text-align: center;
  margin: 20px;
}
</style>
