import { projectFirestore } from "@/firebase/config";
import { ref } from "vue";

const useDocument = (collection, id) => {
    const error = ref(null)
    const isPending = ref(false)

    // Delete a document

    const deleteDoc = async () => {
        isPending.value = true
        error.value = null

        let docRef = projectFirestore.collection(collection).doc(id)

        try {
            const res = await docRef.delete()
            isPending.value = false
            return res

        } catch (err) {
            console.log(err.message)
            error.value = "Could not delete the document"
            isPending.value = false
        }
    }
    const updateDoc = async (updates) => {
        isPending.value = true
        error.value = null

        let docRef = projectFirestore.collection(collection).doc(id)

        try {
            const res = await docRef.update(updates)
            isPending.value = false
            return res

        } catch (err) {
            console.log(err.message)
            error.value = "Could not update the document"
            isPending.value = false
        }


    }


    return { error, isPending, deleteDoc, updateDoc }


}

export default useDocument