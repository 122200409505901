<template>
  <div class="flex flex-row justify-between">
    <div
      class="
        flex flex-row flex-colitems-center
        space-x-4
        cursor-pointer
        overflow-hidden
        hover:bg-gradient-to-r
        from-gray-800
        to-black
        grow
      "
      @click="openYoutubeLink(song.videoUrl)"
    >
      <div class="flex-none w-16 h-16">
        <img
          class="w-16 h-16 rounded-sm"
          v-bind:src="song.thumbnailUrl"
          alt=""
        />
      </div>
      <div class="pt-4">
        <h3 class="font-semibold text-white truncate text-sm">
          {{ song.title }}
        </h3>
        <p class="text-sm text-gray-500 truncate dark:text-gray-400">
          {{ song.artist }}
        </p>
      </div>
    </div>
    <span
      v-if="ownership"
      class="material-icons mr-4 btn-delete-song"
      @click="handleDeleteSong(song.id)"
    >
      delete
    </span>
  </div>
</template>

<script>
import useDocument from "@/composables/useDocument";
import getUser from "@/composables/getUser";
import { computed } from "@vue/runtime-core";

export default {
  props: ["song", "playlist"],
  setup(props) {
    const { updateDoc } = useDocument("playlists", props.playlist.id);
    const { user } = getUser();

    const handleDeleteSong = async (id) => {
      const updatedSongs = props.playlist.songs.filter((song) => song.id != id);
      await updateDoc({ songs: updatedSongs });
    };
    const openYoutubeLink = (url) =>{
        const watchUrl = url.replace("embed/","watch?v=")
        window.open(watchUrl, '_blank');
    }

    const ownership = computed(() => {
      return (
        props.playlist && user.value && user.value.uid == props.playlist.userId
      );
    });

    return { handleDeleteSong, ownership,openYoutubeLink };
  },
};
</script>

<style>
</style>