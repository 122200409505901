<template>
  <div class="navbar sticky top-0 z-50 bg-black lg:bg-transparent">
    <nav>
      <img src="@/assets/logo-bln.png" />
      <h1><router-link class="nav-home" :to="{ name: 'Home' }">Media Box</router-link></h1>
      <div class="links">
        <div v-if="user">
          <router-link class="nav-link" :to="{ name: 'CreatePlaylist' }"
            >Create Playlist</router-link
          >
          <router-link class="nav-link" :to="{ name: 'UserPlaylists' }"
            >My Playlists</router-link
          >
          <span class="text-gray-200">Hi {{ user.displayName }}</span>
          <button class="nav-link" @click="handleLogout()">Logout</button>
        </div>
        <div v-else>
          <router-link class="nav-link" :to="{ name: 'Signup' }">Signup</router-link>
          <router-link class="nav-link" :to="{ name: 'Login' }">Login</router-link>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import useLogout from "@/composables/useLogout";
import getUser from "@/composables/getUser";
import { useRouter } from "vue-router";

export default {
  setup() {
    const { error, logout, isPending } = useLogout();
    const router = useRouter();
    const { user } = getUser();

    const handleLogout = async () => {
      await logout();
      if (!error.value) {
        console.log("user is logged out");
      }
      router.push({ name: "Login" });
    };
    return { handleLogout, user };
  },
};
</script>

<style scoped>
.navbar {
  padding: 16px 20px;
  margin-bottom: 60px;
 
}
nav {
  display: flex;
  align-items: center;
  max-width: 1600px;
  margin: 0 auto;
}
nav img {
  max-height: 60px;
}
nav h1 {
  margin-left: 10px;
}
nav .links {
  margin-left: auto;
}
nav .links a,
button {
  margin-left: 16px;
}
span {
  font-size: 14px;
  display: inline-block;
  margin-left: 16px;
  padding-left: 16px;
  border-left: 1px solid #eee;
}
</style>