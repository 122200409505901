<template>
  <div class="user-playlists px-10">
   <div v-if="playlists">
     <h1 class="page-header">My Playlists</h1>
    <ListView :playlists="playlists"/>
   </div>
  </div>
</template>

<script>
import getCollection from '@/composables/getCollection'
import getUser from '@/composables/getUser'
import ListView from '@/components/ListView.vue'

 
export default {
  components: {ListView},
  setup(){
    const {user} = getUser()
    const {documents:playlists} =getCollection(
      'playlists',
      ['userId','==',user.value.uid]
    )
    return {playlists}
  }

}
</script>

<style>

</style>