<template>
  <div class="grid grid-cols-1 lg:grid-cols-2 space-y-8 place-items-center">
    <div>
      <Intro/>
    </div>
    <div class="w-full rounded-lg md:mt-0 sm:max-w-md xl:p-0 mt-0 bg-black">
      <div class="rounded-sm bg-gradient-to-r from-[#3ba55c] to-[#5865f2] p-1">
        <div class="p-6 space-y-4 md:space-y-2 sm:p-8 bg-black rounded-sm">
          <h1
            class="
              text-xl
              font-bold
              leading-tight
              tracking-tight
              md:text-2xl
              text-white
            "
          >
            Login to your account
          </h1>
          <form
            class="space-y-4 md:space-y-6 bg-transparent border-none"
            @submit.prevent="handleSubmit"
          >
            <div>
              <label
                for="email"
                class="block mb-2 text-sm font-medium text-white"
                >Email</label
              >
              <input
                type="email"
                name="email"
                id="email"
                class="
                  sm:text-sm
                  rounded-lg
                  block
                  w-full
                  p-2.5
                  bg-gray-700
                  placeholder-gray-400
                  text-white
                  border-none
                "
                placeholder="your@email.com"
                required=""
                v-model="email"
              />
            </div>
            <div>
              <label
                for="password"
                class="block mb-2 text-sm font-medium text-white"
                >Password</label
              >
              <input
                type="password"
                name="password"
                id="password"
                placeholder="••••••••"
                class="
                  sm:text-sm
                  rounded-lg
                  block
                  w-full
                  p-2.5
                  bg-gray-700
                  placeholder-gray-400
                  text-white
                  border-none
                "
                required=""
                v-model="password"
              />
            </div>
            <!-- <div class="flex items-center justify-between">
              <div class="flex items-start">
                <div class="flex items-center h-5">
                  <input
                    id="remember"
                    aria-describedby="remember"
                    type="checkbox"
                    class="
                      w-4
                      h-4
                      border border-gray-300
                      rounded
                      bg-gray-50
                      focus:ring-3 focus:ring-primary-300
                      dark:bg-gray-700
                      dark:border-gray-600
                      dark:focus:ring-primary-600
                      dark:ring-offset-gray-800
                    "
                    required=""
                  />
                </div>
                <div class="ml-3 text-sm">
                  <label for="remember" class="text-gray-500 dark:text-gray-300"
                    >Remember me</label
                  >
                </div>
              </div>
              <a
                href="#"
                class="
                  text-sm
                  font-medium
                  text-primary-600
                  hover:underline
                  dark:text-primary-500
                "
                >Forgot password?</a
              >
            </div> -->
            <button type="submit" class="btn-login" v-if="!isPending">
              Login
            </button>
            <button type="submit" class="btn-login" v-else disabled>
              Loading
            </button>
            <p
              class="
                text-sm
                font-light
                text-gray-500
                dark:text-gray-400
                border-solid
              "
            >
              Don’t have an account yet?
              <router-link
                :to="{ name: 'Signup' }"
                class="
                  font-medium
                  text-primary-600
                  hover:underline
                  dark:text-primary-500
                "
                >Sign up</router-link
              >
            </p>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useLogin from "@/composables/useLogin";
import { ref } from "@vue/reactivity";
import { useRouter } from "vue-router";
import Intro from "@/components/Intro.vue";

export default {
  components: { Intro },
  setup() {
    const { error, login, isPending } = useLogin();
    const email = ref("");
    const password = ref("");
    const router = useRouter();

    const handleSubmit = async () => {
      const res = await login(email.value, password.value);
      if (!error.value) {
        router.push({ name: "Home" });
      }
    };

    return { email, password, handleSubmit, error, isPending };
  },
};
</script>

<style>
</style>