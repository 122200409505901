<template>
<Navbar/>
<div class= "content mt-0 lg:mt-8">
    <router-view />
  </div>

  
</template>


 <script>
import Navbar from '@/components/Navbar.vue'
export default{
  components : {Navbar}
}
</script>

<style>
.content {
  margin: 0 auto;
  max-width: 1400px;
  padding: 0 10px;
}

</style>
