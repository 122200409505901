<template>
  <div class="home px-10">
    <div v-if="error" class="error">Could not fetch the data</div>
    <div v-if="documents">
      <h1 class="page-header">Available Playlists</h1>
      <ListView :playlists="documents" />
    </div>
  </div>
</template>

<script>
import getCollection from "@/composables/getCollection";
import ListView from '../components/ListView.vue'

export default {
  name: 'Home',
  components: {ListView},
  setup() {
    const COLLECTION_NAME = "playlists";
    const { error, documents } = getCollection(COLLECTION_NAME);
    if (error.value) {
      console.log("an error has occured: ", error.value);
    } else {
      console.log(documents.value);
    }

    return { error, documents };
  },
};
</script>
