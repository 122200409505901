<template>
  <div
    class="
      flex flex-row flex-colitems-center
      space-x-4
      cursor-pointer
      hover:bg-gradient-to-r
      from-gray-800
      to-black
      grow
    "
    v-on:click="onVideoSelect"
  >
    <div>
      <img class="w-16 h-16 rounded-sm" v-bind:src="thumbnailUrl" alt="" />
    </div>
    <div class="flex-1 min-w-0">
      <p class="font-semibold text-white truncate text-sm">
        {{ video.snippet.title }}
      </p>
      <p class="text-sm text-gray-500 truncate dark:text-gray-400">
        {{ video.snippet.channelTitle }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "VideoListItem",
  props: {
    video: Object,
  },
  computed: {
    thumbnailUrl() {
      return this.video.snippet.thumbnails.default.url;
    },
  },
  methods: {
    onVideoSelect() {
      this.$emit("videoSelect", this.video);
    },
  },
};
</script>

<style scoped>
</style>
