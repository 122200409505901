import { ref } from "@vue/reactivity";
import { projectFunctions } from "../firebase/config";


const getCoverImage = () => {

    const coverImageUrl = ref(null)
    const getImageCoverUrl = projectFunctions.httpsCallable("getImageCoverUrl");


    const load = async () => {
        try {
            let resp = await 
            getImageCoverUrl()
            coverImageUrl.value = resp.data.result
        } catch (err) {
            // Handle Error Here
            console.error(err.message);

        }
    };

    return { coverImageUrl, load }
}
export default getCoverImage