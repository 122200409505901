<template>
  <div class="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1  gap-4">
    <div v-for="playlist in playlists" :key="playlist.id">
      <router-link
        :to="{ name: 'PlaylistDetails', params: { id: playlist.id } }"
      >
        <div class="single flex flex-col">
          <div class="w-72 h-56 mb-4 relative">
            <img class="w-72 h-56 rounded-sm" :src="playlist.coverUrl" />
            <div
              class="
                w-72
                h-56
                absolute
                bottom-0
                right-0
                bg-black
                hover:opacity-70
              transition duration-150
                opacity-0
              "
            >
              <p class="text-sm text-white absolute bottom-2 right-2">
                {{ playlist.songs.length }} songs
              </p>
            </div>
          </div>
          <div class="info">
            <h3 class="font-semibold text-white truncate text-base">
              {{ playlist.title }}
            </h3>
            <p class="text-sm text-gray-400 truncate">
              created by {{ playlist.userName }}
            </p>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: ["playlists"],
};
</script>

<style scoped>
/* .single {
  display: flex;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
  background: white;
  margin: 16px 0;
  transition: all ease 0.2s;
}
.single:hover {
  box-shadow: 1px 2px 3px rgba(50, 50, 50, 0.05);
  transform: scale(1.02);
  transition: all ease 0.2s;
}
.thumbnail {
  max-width: 100px;
  max-height: 100px;
  overflow: hidden;
  border-radius: 10px;
}
img {
  max-width: 150%;
  max-height: 150%;
  display: block;
}
.info {
  margin: 0 30px;
}
.song-number {
  margin-left: auto;
} */
</style>