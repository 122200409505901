<template>
  <div>
    <h1
      class="
        text-transparent
        bg-gradient-to-r
        from-green-300
        via-blue-500
        to-purple-600
        bg-clip-text
        font-semibold
        text-7xl
        font-['Roboto']
      "
    >
      Media Box
    </h1>
    <h1 class="text-gray-300 font-semibold text-xl mt-4">
      A private server for sharing music and videos
    </h1>
    <p class="text-gray-500 font-light text-base">
      Create your playlists using youtube search and share them with your
      friends.
    </p>
    <router-link class=" hover:text-white text-gray-500 font-light  mt-8" :to="{ name: 'Signup' }">Signup and start sharing!</router-link>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>