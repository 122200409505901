<template>
  <div class="grid place-items-center">
    <div class="p-6 space-y-4 md:space-y-2 sm:p-8 bg-black rounded-sm w-96">
      <h1
        class="
          text-xl
          font-bold
          leading-tight
          tracking-tight
          md:text-2xl
          text-white
          mb-8
        "
      >
        Create a new playlist
      </h1>
      <form
        class="bg-black flex flex-col space-y-5"
        @submit.prevent="handleSubmit"
      >
        <input
          class="
            sm:text-sm
            rounded-lg
            block
            w-full
            p-2.5
            bg-gray-700
            placeholder-gray-400
            text-white
            border-none
          "
          type="text"
          required
          placeholder="Playlist tilte"
          v-model="title"
        />
        <textarea
          class="
            sm:text-sm
            rounded-lg
            block
            w-full
            p-2.5
            bg-gray-700
            placeholder-gray-400
            text-white
            border-none
          "
          required
          placeholder="Playlist description ..."
          v-model="description"
        ></textarea>
        <!-- upload playlist image -->
        <!-- <label class="text-white" for="file-upload" >Upload playlist cover image</label>
          <input class="text-sm" id="file-upload" type="file" @change="handlChange" /> -->
        <div class="error">{{ fileError }}</div>
        <button class="btn-login" v-if="!isPending">Create</button>
        <button class="btn-login" v-else disabled>Creating...</button>
      </form>
    </div>
  </div>
</template>

<script>
import Playlist from "@/model/Playlist.js";
import useStorage from "@/composables/useStorage";
import getUser from "@/composables/getUser";
import { ref } from "@vue/reactivity";
import useCollection from "@/composables/useCollection";
import { timestamp } from "@/firebase/config";
import { useRouter } from "vue-router";
import getCoverImage from "@/composables/getCoverImageUrl";

export default {
  setup() {
    const router = useRouter();
    //const { url, filePath, uploadImage } = useStorage();
    const { error, addDoc } = useCollection("playlists");
    const { user } = getUser();
    const isPending = ref(false);
    const { coverImageUrl, load } = getCoverImage();

    const title = ref("");
    const description = ref("");
    const file = ref(null);
    const fileError = ref(null);

    // let playlist = new Playlist(title.value, description.value);

    const handleSubmit = async () => {
      isPending.value = true;
      await load();

      //await uploadImage(file.value);
      const res = await addDoc({
        title: title.value,
        description: description.value,
        userId: user.value.uid,
        userName: user.value.displayName,
        coverUrl: coverImageUrl.value,
        filePath: null,
        songs: [],
        createdAt: timestamp(),
      });
      if (!error.value) {
        router.push({ name: "PlaylistDetails", params: { id: res.id } });
      }

      isPending.value = false;
    };

    // allowed file types
    const types = ["image/png", "image/jpeg"];

    const handlChange = (e) => {
      const selected = e.target.files[0];
      if (selected && types.includes(selected.type)) {
        file.value = selected;
        fileError.value = null;
      } else {
        fileError.value = "Please select an image file (png or jpeg)";
        file.value = null;
      }
    };

    return {
      title,
      description,
      handleSubmit,
      handlChange,
      fileError,
      isPending,
    };
  },
};
</script>

<style>
form {
  background: white;
}
input[type="file"] {
  border: 0;
  padding: 0;
}
label {
  font-size: 12px;
  display: block;
  margin-top: 30px;
}
button {
  margin-top: 20px;
}
</style>