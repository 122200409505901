<template>
  <div v-if="video" class="lg:flex lg:flex-row mt-8 flex flex-col space-y-4 lg:justify-evenly lg:space-x-8">
    <iframe
      class=" lg:basis-3/4 rounded-lg"
      v-bind:src="videoUrl"
      width=""
      height=""
    >
    </iframe>

    <div class="flex flex-col justify-between lg:basis-1/4">
      <div>
        <h4 class="font-semibold text-white text-sm">
          {{ video.snippet.title }}
        </h4>
        <p class="text-xs">{{ video.snippet.channelTitle }}</p>
      </div>
      <div class="pt-4">
        <button class="btn-secondary-mobile lg:btn-secondary" @click="handleAddToPlaylist">
          Add to Playlist
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "@vue/runtime-core";
import { ref } from "@vue/reactivity";
import Song from "@/model/Song";
import useDocument from "@/composables/useDocument";
import { v4 as uuidv4 } from "uuid";

export default {
  name: "VideoDetail",
  props: ["video", "playlist"],
  setup(props) {
    const song = ref(new Song());
    const { updateDoc } = useDocument("playlists", props.playlist.id);

    const videoUrl = computed(() => {
      const { videoId } = props.video.id;
      return `https://www.youtube.com/embed/${videoId}`;
    });

    const handleAddToPlaylist = async () => {
      song.value.id = uuidv4();
      song.value.title = props.video.snippet.title;
      song.value.youtubeChannel = props.video.snippet.channelTitle;
      song.value.artist = props.video.snippet.channelTitle;
      song.value.videoUrl = videoUrl;
      song.value.thumbnailUrl = props.video.snippet.thumbnails.default.url;
      await updateDoc({
        songs: [...props.playlist.songs, { ...song.value }],
      });
    };

    return { videoUrl, handleAddToPlaylist };
  },
};
</script>

<style scoped>
.details {
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}
</style>
