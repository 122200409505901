import firebase from 'firebase/app';
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/functions'



const firebaseConfig = {
    apiKey: "AIzaSyD-JZXW_0ex81u6GIG_3Bqv3CUGpIn7EE0",
    authDomain: "muso-ninjas-53590.firebaseapp.com",
    projectId: "muso-ninjas-53590",
    storageBucket: "muso-ninjas-53590.appspot.com",
    messagingSenderId: "112579707403",
    appId: "1:112579707403:web:67fdff11383c16e04ebd0e"
};

// init firebase
firebase.initializeApp(firebaseConfig)
const app = firebase.app();


// init services
const projectFirestore = firebase.firestore()
const projectAuth = firebase.auth()
const projectStorage = firebase.storage()
const projectFunctions = app.functions("europe-west1")

// timestamp
const timestamp = firebase.firestore.FieldValue.serverTimestamp

export { projectFirestore, projectAuth, projectStorage, projectFunctions, timestamp }