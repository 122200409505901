<template>
  <div class="error" v-if="error">{{ error }}</div>
  <div v-if="playlist" class="grid lg:grid-cols-2 grid-cols-1 gap-16">
    <div class="flex flex-col space-y-4">
      <!-- Playlist Description -->
      <div class="block overflow-hidden rounded-sm">
        <div class="relative">
          <img class="object-cover w-full h-56" :src="playlist.coverUrl" />
          <div
          v-if="ownership"
            class="
              w-full
              h-56
              absolute
              bottom-0
              right-0
              bg-gradient-to-tr from-gray-600 via-gray-900 to-black
              hover:opacity-60
              transition
              duration-150
              opacity-0
              cursor-pointer
            "
            @click="handleChangeImage"
          >
            <p class="text-sm text-white font-semibold absolute top-2 right-10">
              Change cover
            </p>

            <span
              class="
                text-white text-md
                material-symbols-outlined
                absolute
                top-2
                right-2
              "
            >
              autorenew</span
            >
          </div>
        </div>
        <div class="p-4 bg-[#202225] justify-between flex flex-row">
          <div class="playlist-description">
            <p class="text-xs text-gray-500">
              Created by {{ playlist.userName }}
            </p>
            <h5 class="text-sm text-white">
              {{ playlist.title }}
            </h5>
            <p class="mt-1 text-xs text-gray-500">
              {{ playlist.description }}
            </p>
          </div>
          <button class="btn-primary" v-if="ownership" @click="handleDelete">
            Delete
          </button>
        </div>
      </div>
      <div v-if="!playlist.songs.length">
        No songs have been added to this playlist yet
      </div>
      <div v-else class="flex flex-col-reverse">
        <SingleSong
          v-for="song in playlist.songs"
          :key="song.id"
          v-bind:song="song"
          v-bind:playlist="playlist"
        />
      </div>
    </div>
    <AddYoutubeSong v-if="ownership" :playlist="playlist" />
  </div>
</template>

<script>
import getDocument from "@/composables/getDocument";
import getUser from "@/composables/getUser";
import { computed, ref } from "@vue/runtime-core";
import useDocument from "@/composables/useDocument";
// import useStorage from "@/composables/useStorage";
import { useRouter } from "vue-router";
import AddYoutubeSong from "@/components/AddYoutubeSong.vue";
import SingleSong from "@/components/SingleSong.vue";
import getCoverImage from "@/composables/getCoverImageUrl";

export default {
  props: ["id"],
  components: { AddYoutubeSong, SingleSong },
  setup(props) {
    const isPending = ref(false);
    const { coverImageUrl, load } = getCoverImage();
    const { error, document: playlist } = getDocument("playlists", props.id);
    const { user } = getUser();
    const { deleteDoc, updateDoc } = useDocument("playlists", props.id);
    //const { deleteImage } = useStorage();
    const router = useRouter();

    const ownership = computed(() => {
      return (
        playlist.value && user.value && user.value.uid == playlist.value.userId
      );
    });

    const handleDelete = async () => {
      //await deleteImage(playlist.value.filePath);
      await deleteDoc();
      router.push({ name: "Home" });
    };
    const handleDeleteSong = async (id) => {
      const updatedSongs = playlist.value.songs.filter((song) => song.id != id);
      await updateDoc({ songs: updatedSongs });
    };
    const handleChangeImage = async () => {
      isPending.value = true;
      await load();
      await updateDoc({ coverUrl: coverImageUrl.value });
      isPending.value = false;
    };

    return {
      error,
      playlist,
      ownership,
      handleDelete,
      handleDeleteSong,
      handleChangeImage,
    };
  },
};
</script>

<style>
/* .playlist-details {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 80px;
}
.cover {
  overflow: hidden;
  border-radius: 20px;
  position: relative;
  padding: 160px;
}
.cover img {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  max-width: 200%;
  max-height: 200%;
}
.playlist-info {
  text-align: center;
}
.playlist-info h2 {
  text-transform: capitalize;
  font-size: 28px;
  margin-top: 20px;
}
.playlist-info p {
  margin-bottom: 20px;
}
.username {
  color: #999;
}
.description {
  text-align: left;
}
.single-song {
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px dashed var(--secondary);
  margin-bottom: 20px;
} */
</style>