<template>
  <div class="flex flex-col mt-10 space-y-10" v-if="videos.length">
    <div class="text-white font-bold text-2xl		">
      Results
    </div>
    <div class="flex flex-col space-y-4">
      <VideoListItem
        v-for="video in videos"
        v-bind:video="video"
        v-bind:key="video.etag"
        v-on:videoSelect="onVideoSelect"
      >
      </VideoListItem>
    </div>
  </div>
</template>

<script>
import VideoListItem from "./VideoListItem.vue";

export default {
  name: "VideoList",
  props: {
    videos: Array,
  },
  components: {
    VideoListItem,
  },
  methods: {
    onVideoSelect(video) {
      this.$emit("videoSelect", video);
    },
  },
};
</script>

<style scoped></style>
